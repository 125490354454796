<template>
  <div class="my-progress">
    <el-menu
      style="margin: 20px; font-family: Poppins-Regular;"
      :default-active="active"
      mode="horizontal"
      :ellipsis="false"
      @select="handleSelect"
    >
      <el-menu-item index="1" style="font-size: large;">
        <el-badge :value="allArticles.length" max="10">
          All
        </el-badge>  
      </el-menu-item>
      <el-menu-item index="2" style="font-size: large;">
        <el-badge :value="pending.length" max="10">
          Pending
        </el-badge> 
      </el-menu-item>
      <el-menu-item index="3" style="font-size: large;">
        <el-badge :value="approved.length" max="10">
          Approved
        </el-badge> 
      </el-menu-item>
      <el-menu-item index="4" style="font-size: large;">
        <el-badge :value="disapproved.length" max="10">
          Disapproved
        </el-badge>
       </el-menu-item>
      <div class="flex-grow"></div>
      <div style="display: flex; flex-direction: column-reverse; justify-content: space-around;">
        <el-input 
        v-model="input"
        placeholder="Please input key words in title..."
        style="width: 300px; height: 40px;"
        prefix-icon="Search"
        />
      </div>
    </el-menu>

    <lay-quote type="nm" style="font-size: 15px; font-family: Poppins-Regular;">You could manipulate your proposed articles at Proposed Articles page.</lay-quote>

    <div v-if="articles.length > 0">
      <lay-field v-for="(article,index) in searchBy(articles,input)" v-bind:key="index">
        <template #title>
          <div class="my-title"><el-icon style="margin-right: 10px; color:  #111880; font-weight: 900;"><Document /></el-icon>{{article.name}}</div>
        </template>
        <el-space wrap>
          <div style="width: 875px;">
            <el-card style="width: 100%;">
              <template #header>
                <div style="display: flex; justify-content: space-between; font-size: large; margin: 10 auto;">
                  <div>Article Details</div>
                  <div>Status: <el-tag :type="getStatusTag(article.approvedStatus)">{{ article.approvedStatus }}</el-tag> </div>
                </div>
              </template>
              <div>
                <el-descriptions :column="column">
                    <el-descriptions-item label="CreateTime">
                        {{ article.createTime }}
                    </el-descriptions-item>
                    <el-descriptions-item label="Topic">
                        {{ article.topic }}
                    </el-descriptions-item>
                    <el-descriptions-item label="Category">
                        {{ article.category }}
                    </el-descriptions-item>
                    <el-descriptions-item label="Link">
                      <a :href="article.link" target="_blank">{{ article.link }}</a>
                    </el-descriptions-item>
                    <el-descriptions-item label="Source">
                        {{ article.source }}
                    </el-descriptions-item>
                    <el-descriptions-item label="Reading Time">
                        {{ article.reading_time }}
                    </el-descriptions-item>
                    <el-descriptions-item label="User">
                        {{ article.uname }}
                    </el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>
          
            <div style="width: 100%; display: flex; justify-content: space-between;">
              <div>
                <el-card v-for="(question, index) in article.questions" v-bind:key="index" class="box-card" style="width: 450px; margin-top: 10px;">
                  <template #header>
                      <div class="card-header" style="display: flex; justify-content: space-between;">
                        <div>Question{{index+1}}: {{ question.question }}</div>
                        <div>Status: <el-tag :type=" getStatus(question.status).tag">{{ getStatus(question.status).txt }}</el-tag> </div>
                      </div>
                  </template>
                  <div>
                      <el-descriptions :column="column">
                          <el-descriptions-item label="Choice1">
                              {{question.choice1}}
                          </el-descriptions-item>
                          <el-descriptions-item label="Choice2">
                              {{question.choice2}} 
                          </el-descriptions-item>
                          <el-descriptions-item label="Choice3">
                              {{question.choice3}}
                          </el-descriptions-item>
                          <el-descriptions-item label="Choice4">
                              {{question.choice4}}
                          </el-descriptions-item>
                          <el-descriptions-item label="Correct Choice">
                              {{getAns(question.correctChoice)}}
                          </el-descriptions-item>
                          
                      </el-descriptions>
                  </div>
                </el-card>
              </div>
              <el-card style="margin-top: 10px; width: 360px;">
                <template #header>
                    <div class="card-header" style="display: flex; justify-content: space-between;">
                      <div>Rejection Feedback from Moderator</div>
                    </div>
                </template>
                <div v-if="article.feedback!=''">
                  <lay-quote>{{article.feedback}}</lay-quote>
                  <div style="display: flex; flex-direction: row-reverse; 
                    color: #2727a1; font-family: Poppins-Italic; font-size: 12px;">
                    <div>{{ article.feedbackTime }}</div>
                  </div>
                </div>
                <div v-else style="height:100%">
                  <lay-empty  description="No Feedback" style="height: 80px;"></lay-empty>
                </div>
            
              </el-card>
            </div>
          </div>
        </el-space>
      </lay-field>
    </div>
    <lay-empty v-else description="No Articles"></lay-empty>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { get,post } from '../../api';
import {useRoute} from "vue-router";
import {getAns, getStatus} from '@/utils/codeToString'
import {formatDateTime} from "@/utils/dateFormatter"

export default {
  name: "ModerationProgress",
  setup() {
    const input = ref("")
    const active = ref('1')
    const route = useRoute()
    const articles = ref([])
    const column = ref(2)
    const allArticles = ref([])
    const pending = ref([])
    const approved = ref([])
    const disapproved = ref([])

    function searchBy(articles, input) {
      if(input === '') {
        return articles
      } else {
        return articles.filter(article => {
          return article.name.toLowerCase().match(input.toLowerCase())
        })
      }
    }

    function handleSelect(key) {
      console.log(key);
      active.value = key
      switch(key) {
        case "1":
          articles.value = allArticles.value
          break
        case "2":
          articles.value = pending.value
          break
        case "3":
          articles.value = approved.value
          break
        case "4":
          articles.value = disapproved.value
          break
      }
    }

    function load() {
      get('/article/id/user/' + route.query.uid).then(res=>{
        console.log(res.articles)
        allArticles.value = res.articles
        for(let i in allArticles.value){
          post('/user/get-user-name', {uid: allArticles.value[i].uid}).then(res => {
            allArticles.value[i].uname = res.uname
          })
          allArticles.value[i].createTime = formatDateTime(new Date(allArticles.value[i].createTime), 'yyyy-MM-dd HH:mm:ss')
          post('/question/get-question', {articleId:allArticles.value[i].id} ).then(res=> {
            allArticles.value[i].questions = res.questions
          })
          get('/reject/get-by-article', {"id": allArticles.value[i].id}).then(res=> {
            if(res.reject == null) {
              allArticles.value[i].feedback = ""
            } else {
              allArticles.value[i].feedback = res.reject.feedback
              allArticles.value[i].feedbackTime = formatDateTime(new Date(res.reject.time), 'yyyy-MM-dd HH:mm:ss')
            }
            
          })
        }
        articles.value = allArticles.value
        
        for (let i in allArticles.value) {
          let stat = allArticles.value[i].approvedStatus
          switch(stat) {
            case "article_approved":
              pending.value.push(allArticles.value[i])
              break
            case "await_modification":
              pending.value.push(allArticles.value[i])
              break
            case "under_assessment":
              pending.value.push(allArticles.value[i])
              break
            case "question_uploaded":
              pending.value.push(allArticles.value[i])
              break
            case "all_approved":
              approved.value.push(allArticles.value[i])
              break
            case "question_disapproved":
              disapproved.value.push(allArticles.value[i])
              break
            case "disapproved":
              disapproved.value.push(allArticles.value[i])
              break
          }
        }
        
      })
    }

    function getStatusTag(status) {
      switch (status) {
        case 'pending':
          return 'warning';
        case 'approved':
          return 'success';
        case 'disapproved':
          return 'danger';
        default:
          return 'info';
      }
    }

    load()

    return{
      articles,
      column,
      getAns,
      getStatus,
      active,
      handleSelect,
      input,
      searchBy,
      allArticles,
      pending,
      approved,
      disapproved,
      getStatusTag
    }
  }
}
</script>

<style scoped>
:deep(.el-input__wrapper) {
  border-radius: 15px;

}

:deep(.el-input-group__append) {
  border-radius: 15px;
}
.flex-grow {
  flex-grow: 1;
}
.my-progress {
  margin: 20px;
}

.my-title {
  font-family: Poppins-SemiBold;
  color:  #111880;
}
</style>
